export const STORAGE_BOARD_KEY = 'erxesCurrentBoardId';
export const STORAGE_PIPELINE_KEY = 'erxesCurrentPipelineId';

export const PRIORITIES = ['Cực kỳ quan trọng', 'Cao', 'Bình thường', 'Thấp'];
export const COLORS = [
  '#01aecc',
  '#D9E3F0',
  '#F47373',
  '#697689',
  '#4bbf6b',
  '#0078bf',
  '#89609d',
  '#838c91',
  '#cd5a91',
  '#d29034',
  '#63D2D6',
  '#F7CE53'
];
export const REMINDER_MINUTES = [
  { _id: '0', name: 'thời điểm hạn cuối' },
  { _id: '5', name: '5 phút trước' },
  { _id: '10', name: '10 phút trước' },
  { _id: '15', name: '15 phút trước' },
  { _id: '60', name: '1 giờ trước' },
  { _id: '120', name: '2 giờ trước' },
  { _id: '1440', name: '1 ngày trước' },
  { _id: '2880', name: '2 ngày trước' }
];
