// export const metricOptions = [
//   {
//     label: 'GROWTHHACKERS RECOMMENDED',
//     options: [
//       { value: 'monthly-active-users', label: 'Monthly Active Users' },
//       { value: 'weekly-orders', label: 'Weekly Orders' },
//       { value: 'monthly-orders', label: 'Monthly Orders' },
//       { value: 'repeat-purchases', label: 'Repeat Purchases (Total)' }
//     ]
//   },
//   {
//     label: 'YOUR METRICS',
//     options: [
//       { value: 'daily-active-users', label: 'Daily Active Users' },
//       { value: 'weekly-active-users', label: 'Weekly Active Users' }
//     ]
//   },
//   {
//     label: 'COMMON METRICS',
//     options: [
//       { value: 'active-subscriptions', label: 'Active Subscriptions (Total)' },
//       { value: 'weekly-revenue', label: 'Weekly Revenue' },
//       { value: 'monthly-revenue', label: 'Monthly Revenue' },
//       { value: 'repeat-recurring-revenue', label: 'Repeat Recurring Revenue' }
//     ]
//   },
//   {
//     label: 'GROWTH MATRIX',
//     options: [
//       { value: 'viral-coefficient', label: 'Viral coefficient' },
//       {
//         value: 'free-to-paid-conversion-rate',
//         label: 'Free-to-paid conversion rate'
//       },
//       { value: 'number-of-deals-created', label: 'Number of deals created' },
//       { value: 'deal-to-close-rate', label: 'Deal-to-close rate' }
//     ]
//   }
// ];
export const metricOptions = [
  {
    label: 'ĐƯỢC KHUYẾN NGHỊ BỞI GROWTHHACKERS',
    options: [
      { value: 'monthly-active-users', label: 'Người dùng hoạt động hàng tháng' },
      { value: 'weekly-orders', label: 'Đơn hàng hàng tuần' },
      { value: 'monthly-orders', label: 'Đơn hàng hàng tháng' },
      { value: 'repeat-purchases', label: 'Mua lại (Tổng cộng)' }
    ]
  },
  {
    label: 'CÁC CHỈ SỐ CỦA BẠN',
    options: [
      { value: 'daily-active-users', label: 'Người dùng hoạt động hàng ngày' },
      { value: 'weekly-active-users', label: 'Người dùng hoạt động hàng tuần' }
    ]
  },
  {
    label: 'CÁC CHỈ SỐ PHỔ BIẾN',
    options: [
      { value: 'active-subscriptions', label: 'Đăng ký hoạt động (Tổng cộng)' },
      { value: 'weekly-revenue', label: 'Doanh thu hàng tuần' },
      { value: 'monthly-revenue', label: 'Doanh thu hàng tháng' },
      { value: 'repeat-recurring-revenue', label: 'Doanh thu định kỳ lặp lại' }
    ]
  },
  {
    label: 'MA TRẬN TĂNG TRƯỞNG',
    options: [
      { value: 'viral-coefficient', label: 'Hệ số lan truyền' },
      {
        value: 'free-to-paid-conversion-rate',
        label: 'Tỷ lệ chuyển đổi từ miễn phí sang trả phí'
      },
      { value: 'number-of-deals-created', label: 'Số lượng giao dịch được tạo' },
      { value: 'deal-to-close-rate', label: 'Tỷ lệ chốt giao dịch' }
    ]
  }
];