export const INSIGHT_TYPES = {
  INBOX: 'hộp thư đến',
  DEAL: 'giao dịch',
  ALL: ['hộp thư đến', 'giao dịch']
};

export const INBOX_INSIGHTS = [
  {
    name: 'Báo cáo Khối lượng',
    image: '/images/icons/erxes-19.svg',
    to: '/inbox/insights/volume-report',
    desc: `Tổng số phiên hội thoại, được thực hiện với khách hàng qua mọi tích hợp`
  },
  {
    name: 'Báo cáo Phản hồi',
    image: '/images/icons/erxes-15.svg',
    to: '/inbox/insights/response-report',
    desc: `Báo cáo về tổng số phản hồi của khách hàng được cung cấp bởi các thành viên trong nhóm`
  },
  {
    name: 'Báo cáo Đóng Phản hồi',
    image: '/images/icons/erxes-06.svg',
    to: '/inbox/insights/response-close-report',
    desc: `Thời gian trung bình mà một thành viên trong nhóm giải quyết vấn đề của khách hàng dựa trên phản hồi của khách hàng`
  },
  {
    name: 'Báo cáo Phản hồi Đầu tiên',
    image: '/images/icons/erxes-16.svg',
    to: '/inbox/insights/first-response',
    desc: 'Bạn có thể tìm thấy các thống kê xác định thời gian phản hồi trung bình của từng thành viên trong nhóm'
  },
  {
    name: 'Báo cáo Khối lượng Theo Khách hàng',
    image: '/images/icons/erxes-23.svg',
    to: '/inbox/insights/summary-report',
    desc: 'Tổng số tin nhắn được gửi bởi khách hàng qua mọi tích hợp'
  },
  {
    name: 'Báo cáo Xuất',
    image: '/images/icons/erxes-22.svg',
    to: '/inbox/insights/export-report',
    desc: 'Tải dữ liệu tìm hiểu sâu dưới dạng bảng tính excel'
  },
  {
    name: 'Báo cáo Tần suất Phản hồi',
    image: '/images/icons/erxes-02.svg',
    to: '/inbox/insights/conversation-report',
    desc: 'Tốc độ phản hồi chung cho yêu cầu của khách hàng'
  }
];

export const DEAL_INSIGHTS = [
  {
    name: 'Báo cáo Giao dịch Thành công',
    image: '/images/icons/deal-insight-won.svg',
    to: '/deal/insights/won',
    desc: 'Tìm phản hồi về các giao dịch giành được đã được tạo bởi các thành viên trong nhóm'
  },
  {
    name: 'Báo cáo Giao dịch Thất bại',
    image: '/images/icons/deal-insight-lost.svg',
    to: '/deal/insights/lost',
    desc: 'Tìm phản hồi về các giao dịch bị mất đã được tạo bởi các thành viên trong nhóm'
  },
  {
    name: 'Báo cáo Khối lượng Giao dịch',
    image: '/images/icons/deal-insight-volume.svg',
    to: '/deal/insights/volume-report',
    desc: 'Tìm phản hồi đã được tạo bởi các thành viên trong nhóm'
  }
];