import AnimatedLoader from 'modules/common/components/AnimatedLoader';
import Spinner from 'modules/common/components/Spinner';
import React from 'react';
import { IAnimatedLoader } from '../types';

/**
 * là một Higher Order Component (HOC) trong React, 
 * được sử dụng để tải các thành phần một cách không đồng bộ (lazy loading). 
 * Điều này giúp cải thiện hiệu suất của ứng dụng bằng cách chỉ tải các thành phần
 * khi cần thiết, thay vì tải tất cả các thành phần ngay từ đầu.
 * @param importComponent 
 * @param loaderStyle 
 * @returns 
 */
export default function asyncComponent(
  importComponent: any,
  loaderStyle?: IAnimatedLoader
) {
  class AsyncComponent extends React.Component<any, { component: any }> {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({ component });
    }

    render() {
      const Comp = this.state.component;

      if (Comp) {
        return <Comp {...this.props} />;
      }

      if (loaderStyle) {
        return <AnimatedLoader loaderStyle={loaderStyle} />;
      }

      return <Spinner />;
    }
  }

  return AsyncComponent;
}
